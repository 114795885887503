<template>
  <div>
    <div class="flex">
      <div id="filter-area">
        <el-input
          v-model="table.filters[0].value"
          placeholder="Search Name / Host"
        />
      </div>
      <div
        id="button-area"
        class="ml-auto"
      >
        <!-- <el-button
          type="danger"
          @click="clearAllStream"
        >
          Clear All Stream
        </el-button>
        <el-button
          type="warning"
          @click="syncStream"
        >
          Sync Stream
        </el-button> -->
        <el-button
          v-show="$store.getters.getCurrentAuthUser.data.role_id.name === 'Admin'"
          type="primary"
          @click="create"
        >
          Add Camera
        </el-button>
      </div>
    </div>
    <data-tables-server
      v-loading="table.loading"
      :data="table.data"
      :total="table.total"
      :filters="table.filters"
      :pagination-props="table.pagination.props"
      @query-change="loadData"
    >
      <el-table-column
        label="Camera Name"
        prop="name"
        sortable="custom"
      />
      <el-table-column
        label="Host"
        prop="host"
        sortable="custom"
        width="100"
      />
      <!-- <el-table-column
        label="Stream ID"
        prop="stream_id"
      > -->
      <!-- <template slot-scope="scope">
          <template v-if="scope.row.stream_id">
            <el-tag
              size="mini"
              type="success"
            >
              {{ scope.row.stream_id }}
            </el-tag>
          </template>
          <template v-else>
            <el-button
              size="mini"
              type="warning"
              @click="createAntStream(scope.row)"
            >
              Generate
            </el-button>
          </template>
        </template> -->
      <!-- </el-table-column> -->
      <!-- <el-table-column label="Coordinates">
        <template slot-scope="scope">
          <template v-if="scope.row.latitude">
            {{ scope.row.latitude }}, {{ scope.row.longitude }}
          </template>
          <template v-if="!scope.row.latitude">
            N/A
          </template>
        </template>
      </el-table-column> -->
      <el-table-column
        label="Created At"
        prop="created_at"
        sortable="custom"
        width="160"
      >
        <template slot-scope="scope">
          {{ $moment(scope.row.created_at).format('DD MMM YYYY HH:mm:ss') }}
        </template>
      </el-table-column>
      <el-table-column
        label="Is Public"
        width="90"
      >
        <template slot-scope="scope">
          <el-tag
            size="mini"
            effect="dark"
            :type="parse.public[scope.row.isPublic].type"
          >
            {{ parse.public[scope.row.isPublic].label }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
        label="Map"
        width="90"
      >
        <template slot-scope="scope">
          <el-tag
            v-if="scope.row.latitude"
            size="mini"
            effect="dark"
            type="success"
          >
            Active
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
        label="Status"
        width="90"
      >
        <template slot-scope="scope">
          <el-tag
            size="mini"
            effect="dark"
            :type="parse.status[scope.row.status].type"
          >
            {{ parse.status[scope.row.status].label }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
        width="160"
        align="right"
      >
        <template slot-scope="props">
          <el-button
            v-show="$store.getters.getCurrentAuthUser.data.role_id.name === 'Admin'"
            plain
            type="primary"
            icon="el-icon-edit"
            circle
            @click="update(props.row)"
          />
          <el-button
            plain
            type="info"
            icon="el-icon-search"
            circle
            @click="detail(props.row)"
          />
        </template>
      </el-table-column>
    </data-tables-server>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  data() {
    return {
      table: {
        loading: false,
        data: [],
        params: {
          filter: []
        },
        queryInfo: null,
        total: 0,
        filters: [
          {
            value: null,
            op: 'cs',
            search_prop: ['name', 'host']
          }
        ],
        props: {
          border: true
        },
        pagination: {
          props: {
            pageSizes: [5, 10],
            background: true
          }
        }
      },
      parse: {
        status: {
          '0': {
            label: 'Inactive',
            type: 'info'
          },
          '1': {
            label: 'Active',
            type: 'success'
          }
        },
        public: {
          '0': {
            label: 'Private',
            type: 'primary'
          },
          '1': {
            label: 'Public',
            type: 'success'
          }
        }
      }
      
    }
  },
  mounted() {},
  methods: {
    async render() {
      // this.table.data = await this.getUsersData();
    },
    async loadData(queryInfo) {
      this.table.loading = true;
      this.table.queryInfo = queryInfo;
      this.filterHandler();
      this.orderHandler();
      this.pageHandler();
      let { records, results } = await this.getData(this.table.params);
      this.table.data = records;
      this.table.total = results;
      this.table.loading = false;
    },
    pageHandler() {
      const queryInfo = this.table.queryInfo;
      this.table.params.page = [queryInfo.page, queryInfo.pageSize].join(',');
    },
    orderHandler() {
      const queryInfo = this.table.queryInfo;
      this.table.params.order = ['created_at', 'desc'].join(',');
      if(queryInfo.type === 'sort') {
        const orderBy = queryInfo.sort.order === 'ascending' ? 'asc' : 'desc';
        this.table.params.order = [queryInfo.sort.prop, orderBy].join(',');
      }
    },
    filterHandler() {
      const queryInfo = this.table.queryInfo;
      this.table.params.filter = [];
      for(let search_prop of queryInfo.filters[0].search_prop) {
        this.table.params.filter.push([search_prop, queryInfo.filters[0].op, queryInfo.filters[0].value].join(','));
      }
    },
    getData(params = {}) {
      const searchParams = new URLSearchParams();
      searchParams.append('page', params.page);
      this.table.params.filter.forEach((val, index) => {
        searchParams.append(`filter${index}`, val);
      })
      searchParams.append('order', params.order);
      return new Promise((resolve, reject) => {
        this.$request.get('records/cameras', { params: searchParams })
          .then(response => {
            resolve(response.data);
          })
          .catch(err => {
            reject(err);
          })
      })
    },
    create() {
      const data = {
        type: 'create'
      }
      this.$emit('openDialog', data);
    },
    detail(row) {
      const data = {
        type: 'detail',
        data: row
      }
      this.$emit('openDialog', data);
    },
    update(row) {
      const data = {
        type: 'update',
        data: row
      }
      this.$emit('openDialog', data);
    },
    getCameras() {
      return new Promise((resolve) => {
        this
          .$request
          .get('records/cameras')
          .then(response => {
            resolve(response.data.records);
          })
      })
    },
    async clearAllStream() {
      await axios.get(process.env.VUE_APP_ANT_URL + '/list/0/50').then(async response => {
        for(let stream of response.data) { await this.deleteStream(stream.streamId) }
      });
    },
    async deleteStream(stream_id) {
      return new Promise((resolve) => {
        axios.delete(process.env.VUE_APP_ANT_URL + '/' + stream_id).then(response => {
          resolve(response);
        });
      })
    },
    async syncStream() {
      const cameras = await this.getCameras();
      for(let camera of cameras) {
        await this.createAntStream(camera);
      }
      this.$emit('renderData');
    },
    async createAntStream(data) {
      return new Promise(async (resolve) => {
        const antResult = await this.insertAntCamera(data.host);
        await this.updateCameraStreamId(data.id, antResult.message);
        resolve(true);
      })
    },
    updateCameraStreamId(id, stream_id) {
      return new Promise((resolve, reject) => {
        const data = { stream_id };
        this.$request.put(`records/cameras/${id}`, data)
          .then(response => {
            resolve(response.data);
          })
          .catch(err => {
            this.$message({
              type: 'error',
              message: err.response.data.message
            });
            reject(err);
          })
      })
    },
    insertAntCamera(host) {
      return new Promise((resolve, reject) => {
        const data = {
          type: "streamSource",
          name: host,
          streamUrl: `rtsp://admin:Admin123@${host}/streaming/channels/101`
        }
        axios
          .post(process.env.VUE_APP_ANT_URL + '/create?autoStart=true', data)
          .then(response => {
            resolve(response.data); 
          })
          .catch((err) => {
            reject(err.response);
          })
      })
    }
  }
}
</script>

<style>

</style>