<template>
  <div v-if="loaded">
    <div class="flex flex-row">
      <dl class="w-1/6">
        <dt>Camera Name</dt>
        <dd>{{ data.name }}</dd>
      </dl>
      <dl class="w-2/6">
        <dt>Camera Host</dt>
        <dd>{{ data.host }}</dd>
        <dt>Stream ID</dt>
        <dd>{{ data.stream_id }}</dd>
      </dl>
      <dl class="w-2/6">
        <dt>Tipe Jalan</dt>
        <dd>
          {{ data.isIntersection ? 'Persimpangan' : 'Ruas' }}
        </dd>
        <dt>Alamat</dt>
        <dd>
          {{ data.address ? data.address : 'N/A' }}
        </dd>
        <dt>Latitude</dt>
        <dd>
          {{ data.latitude ? data.latitude : 'N/A' }}
        </dd>
        <dt>Longitude</dt>
        <dd>
          {{ data.longitude ? data.longitude : 'N/A' }}
        </dd>
      </dl>
      <dl class="w-1/6">
        <dt>Public Status</dt>
        <dd>
          <el-tag
            size="mini"
            effect="dark"
            :type="parse.public[data.isPublic].type"
          >
            {{ parse.public[data.isPublic].label }}
          </el-tag>
        </dd>
        <dt>Status</dt>
        <dd>
          <el-tag
            size="mini"
            effect="dark"
            :type="parse.status[data.status].type"
          >
            {{ parse.status[data.status].label }}
          </el-tag>
        </dd>
      </dl>
    </div>
    <div class="flex flex-row">
      <dl class="w-full">
        <dt>Camera Preview</dt>
        <dd>
          <template v-if="user.data.role_id.stream_mode === 'passive'">
            <fullscreen
              ref="fullscreen"
              @change="fullscreenChange"
            >
              <AutoReloadImage :src="`http://proxy.cctv.malangkota.go.id/image?host=${data.host}`" />
            </fullscreen>
          </template>
          <iframe
            v-if="user.data.role_id.stream_mode === 'active'"
            :id="data.stream_id"
            :src="`//stream.cctv.malangkota.go.id:/WebRTCApp/play.html?name=${data.stream_id}&autoplay=true`"
            frameborder="0"
            allowfullscreen="true"
            seamless="seamless"
          />
        </dd>
      </dl>
    </div>
  </div>
</template>

<script>
import AutoReloadImage from '../../../../components/AutoReloadImage.component';
import Fullscreen from 'vue-fullscreen/src/component.vue';
export default {
  components: { AutoReloadImage, Fullscreen },
  props: ['id'],
  data() {
    return {
      user: null,
      loaded: false,
      data: {},
      parse: {
        status: {
          '0': {
            label: 'Inactive',
            type: 'info'
          },
          '1': {
            label: 'Active',
            type: 'success'
          }
        },
        public: {
          '0': {
            label: 'Private',
            type: 'primary'
          },
          '1': {
            label: 'Public',
            type: 'success'
          }
        }
      }
    }
  },
  async created() {
    this.data = await this.getData();
    this.user = this.$store.getters.getCurrentAuthUser;
    this.loaded = true;
    this.$emit('loadingHandler', false);
  },
  methods: {
    getData() {
      return new Promise((resolve, reject) => {
        this.$request.get(`records/cameras/${this.$props.id}`)
          .then(response => {
            resolve(response.data);
          })
          .catch(err => {
            reject(err);
          })
      })
    }
  }
}
</script>

<style scoped>
dt {
  @apply text-blue-500;
}

dd {
  @apply mb-4
}
</style>