<template>
  <div>
    <el-form
      :model="form"
      label-position="top"
    >
      <div class="flex flex-wrap -mx-2">
        <div class="w-1/3 px-2">
          <el-form-group
            name="Camera Name"
            label="Camera Name"
            :validator="$v.form.name"
          >
            <el-input
              v-model="form.name"
              placeholder="Camera Name"
              @input="$v.form.name.$touch()"
            />
          </el-form-group>
          <el-form-group
            name="Camera IP"
            label="Camera IP"
            :validator="$v.form.host"
          >
            <el-input
              v-model="form.host"
              placeholder="Camera IP"
              @input="$v.form.host.$touch()"
            />
          </el-form-group>
          <!-- <el-form-group label="Stream ID">
            <el-input
              v-model="form.stream_id"
              placeholder="Stream ID"
            />
          </el-form-group> -->
        </div>
        <div class="w-1/3 px-2">
          <el-form-group label="Address">
            <el-input
              v-model="form.address"
              disabled
              placeholder="Address"
            />
          </el-form-group>
          <el-form-group label="Latitude">
            <el-input
              v-model="form.latitude"
              disabled
              placeholder="Latitude"
            />
          </el-form-group>
          <el-form-group label="Longitude">
            <el-input
              v-model="form.longitude"
              disabled
              placeholder="Longitude"
            />
          </el-form-group>
        </div>
        <div class="w-1/3 px-2">
          <el-form-group
            class="w-full"
            label="Tipe Jalan"
          >
            <el-switch
              v-model="form.isIntersection"
              active-text="Persimpangan"
              inactive-text="Ruas Jalan"
            />
          </el-form-group>
          <el-form-group
            class="w-full"
            label="Is Public"
          >
            <el-switch
              v-model="form.isPublic"
              active-text="Yes"
              inactive-text="No"
            />
          </el-form-group>
          <el-form-group
            class="w-full"
            label="Status"
          >
            <el-switch
              v-model="form.status"
              active-text="Active"
              inactive-text="Inactive"
            />
          </el-form-group>
        </div>
      </div>
    </el-form>
    <div
      slot="footer"
      class="dialog-footer flex justify-between"
    >
      <div>
        <el-button
          v-if="mode === 'update'"
          type="danger"
          @click="doDelete"
        >
          Delete
        </el-button>
        <el-button
          v-if="mode === 'update'"
          type="warning"
          plain
          outline
          @click="doClearAddress"
        >
          Hapus Alamat dan Koordinat
        </el-button>
      </div>
      <div>
        <el-button @click="closeDialog">
          Cancel
        </el-button>
        <el-button
          class="ml-auto"
          type="primary"
          :disabled="$v.$invalid"
          @click="doSave"
        >
          Save
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>

import { required } from "vuelidate/lib/validators";
import axios from 'axios';
const uuidv4 = require('uuid/v4');

export default {
  props: ['id'],
  data() {
    return {
      mode: 'create',
      data: {
        name: null
      },
      form: {
        id: null,
        name: '',
        host: '',
        stream_id: '',
        address: '',
        latitude: '',
        longitude: '',
        isIntersection: false,
        isPublic: false,
        status: false,
        users: []
      }
    }
  },
  async created() {
    this.mode = this.$props.id ? 'update' : 'create';
  },
  async mounted() {
    if(this.mode === 'create') {
      this.form.id = uuidv4();
    } else {
      this.data = await this.getData();
      const { id, name, host, stream_id, address, latitude, longitude, isIntersection, isPublic, status } = this.data;
      this.form.id = id;
      this.form.name = name;
      this.form.host = host;
      this.form.stream_id = stream_id;
      this.form.address = address;
      this.form.latitude = latitude;
      this.form.longitude = longitude;
      this.form.isIntersection = isIntersection ? true : false;
      this.form.isPublic = isPublic ? true : false;
      this.form.status = status ? true : false;
      this.$v.$touch();
      this.$emit('loadingHandler', false);
    }
  },
  validations: {
    form: {
      name: { required },
      host: { required }
    }
  },
  methods: {
    create() {
      return new Promise((resolve, reject) => {
        const data = {
          id: this.form.id,
          name: this.form.name,
          host: this.form.host,
          // stream_id: this.form.stream_id ? this.form.stream_id : null,
          address: this.form.address ? this.form.address : null,
          latitude: this.form.latitude ? this.form.latitude : null,
          longitude: this.form.longitude ? this.form.longitude : null,
          created_at: null,
          isIntersection: this.form.isIntersection ? 1 : 0,
          isPublic: this.form.isPublic ? 1 : 0,
          status: this.form.status ? 1 : 0,
        }
        this.$request.post('records/cameras', data)
          .then(async response => {
            const camera_id = response.data;
            const antResult = await this.insertAntCamera(this.form.host);
            await this.updateCameraStreamId(camera_id, antResult.message);
            resolve(response.data);
          })
          .catch(err => {
            this.$message({
              type: 'error',
              message: err.response.data.message
            });
            reject(err);
          })
      })
    },
    update() {
      return new Promise((resolve, reject) => {
        const data = {
          name: this.form.name,
          host: this.form.host,
          // stream_id: this.form.stream_id ? this.form.stream_id : null,
          address: this.form.address ? this.form.address : null,
          latitude: this.form.latitude ? this.form.latitude : null,
          longitude: this.form.longitude ? this.form.longitude : null,
          updated_at: null,
          isIntersection: this.form.isIntersection ? 1 : 0,
          isPublic: this.form.isPublic ? 1 : 0,
          status: this.form.status ? 1 : 0,
        }
        this.$request.put(`records/cameras/${this.form.id}`, data)
          .then(response => {
            resolve(response.data);
          })
          .catch(err => {
            this.$message({
              type: 'error',
              message: err.response.data.message
            });
            reject(err);
          })
      })
    },
    delete() {
      return new Promise(async (resolve, reject) => {
        this.$request.delete(`records/cameras/${this.form.id}`)
          .then(async response => {
            await this.deleteAntCamera(this.form.stream_id);
            resolve(response.data);
          })
          .catch(err => {
            this.$message({
              type: 'error',
              message: err.response.data.message
            });
            reject(err);
          })
      })
    },
    async createData() {
      this.$emit('loadingHandler', true);
      return new Promise(async (resolve, reject) => {
        this.create().then(() => {
          this.$emit('loadingHandler', false);
          this.$store.dispatch('RELOAD', 'panel');
          resolve('DONE')
        }).catch(() => {
          this.$emit('loadingHandler', false);
          reject('ERROR');
        });
      })
    },
    async updateData() {
      return new Promise(async (resolve) => {
        await this.update();
        resolve('DONE')
      })
    },
    async deleteData() {
      return new Promise(async (resolve) => {
        await this.delete();
        this.$store.dispatch('RELOAD', 'panel');
        resolve('DONE')
      })
    },
    getData() {
      return new Promise((resolve, reject) => {
        this.$request.get(`records/cameras/${this.$props.id}`)
          .then(response => {
            resolve(response.data);
          })
          .catch(err => {
            reject(err);
          })
      })
    },
    closeDialog() {
      this.$emit('closeDialog');
    },
    async doSave() {
      this.$confirm('Are you sure?', 'Warning', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(async () => {
        (this.mode === 'create') ? await this.createData() : await this.updateData();
        this.$emit('closeDialog');
        this.$emit('renderData');
        this.$message({
          type: 'success',
          message: 'Operation Success'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: 'Operation Cancelled'
        });          
      });
    },
    async doDelete() {
      this.$confirm('Are you sure?', 'Warning', {
        confirmButtonText: 'Delete',
        confirmButtonClass: 'el-button--danger',
        cancelButtonText: 'Cancel',
        type: 'error'
      }).then(async () => {
        await this.deleteData();
        this.$emit('closeDialog');
        this.$emit('renderData');
        this.$message({
          type: 'success',
          message: 'Operation Success'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: 'Operation Cancelled'
        });          
      });
    },
    async doClearAddress() {
      this.$confirm('Are you sure?', 'Warning', {
        confirmButtonText: 'Clear',
        confirmButtonClass: 'el-button--warning',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(async () => {
        this.form.address = '';
        this.form.latitude = '';
        this.form.longitude = '';
      }).catch(() => {
        this.$message({
          type: 'info',
          message: 'Operation Cancelled'
        });          
      });
    },
    updateCameraStreamId(id, stream_id) {
      return new Promise((resolve, reject) => {
        const data = { stream_id };
        this.$request.put(`records/cameras/${id}`, data)
          .then(response => {
            resolve(response.data);
          })
          .catch(err => {
            this.$message({
              type: 'error',
              message: err.response.data.message
            });
            reject(err);
          })
      })
    },
    insertAntCamera(host) {
      return new Promise((resolve, reject) => {
        const data = {
          type: "streamSource",
          name: host,
          streamUrl: `rtsp://admin:Admin123@${host}/streaming/channels/101`
        }
        axios
          .post(process.env.VUE_APP_ANT_URL + '/create?autoStart=true', data)
          .then(response => {
            resolve(response.data); 
          })
          .catch((err) => {
            reject(err.response);
          })
      })
    },
    async deleteAntCamera(stream_id) {
      return new Promise((resolve) => {
        axios.delete(process.env.VUE_APP_ANT_URL + '/' + stream_id).then(response => {
          resolve(response);
        });
      })
    },
  }
}
</script>

<style>

</style>